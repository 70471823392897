<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{$t('elearning_config.duplicate_certificate_fee')}} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col lg="6" sm="12">
                        <!-- <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('elearning_config.organization')"
                        label-for="organization"
                        >
                        <v-select name="organization"
                        v-model="search.org_id"
                        label="text"
                        :reduce="item => item.value"
                        :options= orgList
                        />
                        </b-form-group> -->
                        <b-form-group
                  class="row"
                  label-cols-sm="5"
                  label-for="org_id"
                  >
                  <template v-slot:label>
                    {{ $t('globalTrans.organization')}}
                  </template>
                  <v-select name="org_id"
                    v-model="search.org_id"
                    label="text"
                    :reduce="item => item.value"
                    :options= orgList
                    :placeholder="$t('globalTrans.select')"
                    :filter-by="myFilter"
                  />
                </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <!-- <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('elearning_config.office_type')"
                        label-for="office_type"
                        >
                        <v-select name="office_type"
                        v-model="search.office_type_id"
                        label="text"
                        :reduce="item => item.value"
                        :options= officeTypeList
                        />
                        </b-form-group> -->
                        <b-form-group
                  class="row"
                  label-cols-sm="5"
                  label-for="office_type_id"
                  >
                  <template v-slot:label>
                    {{ $t('elearning_config.office_type')}}
                  </template>
                  <v-select name="office_type_id"
                    v-model="search.office_type_id"
                    label="text"
                    :reduce="item => item.value"
                    :options= officeTypeList
                    :placeholder="$t('globalTrans.select')"
                    :filter-by="myFilter"
                  />
                </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('elearning_config.duplicate_certificate_fee')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                        <b-button class="btn_add_new" v-b-modal.modal-4 @click="resetId">
                             <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(fee_amount)="data">
                                        <span class="capitalize">{{ $n(data.item.fee_amount) }}</span>
                                        </template>
                                        <template v-slot:cell(effective_date)="data">
                                        <span class="capitalize">{{ data.item.effective_date | dateFormat }}</span>
                                        </template>
                                        <template v-slot:cell(status)="data">
                                        <span class="capitalize badge badge-success" v-if="data.item.status === 1">{{ $t('globalTrans.active') }}</span>
                                        <span class="capitalize badge badge-danger" v-if="data.item.status === 2">{{ $t('globalTrans.inactive') }}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-5 @click="details(data.item)"><i class="fas fa-eye"></i></a>
                                            <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 @click="edit(data.item)"><i class="ri-ball-pen-fill"></i></a>
                                            <a href="javascript:" class="btn_table_action table_action_status" title="Status" v-if="data.item.status === 1" @click="remove(data.item)"><i class="fas fa-toggle-on"></i></a>
                                            <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" v-if="data.item.status === 2" @click="remove(data.item)"><i class="fa fa-toggle-off"></i></a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-5" size="lg" :title="$t('elearning_config.duplicate_certificate_fee')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Details :item="item" :key="id"></Details>
        </b-modal>

    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { duplicateCertificateFeeList, duplicateCertificateFeeToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Details from './Details'
import Store from '@/store'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form, Details
    },
    data () {
        return {
            search: {
                org_id: '',
                office_type_id: '',
                office_id: 0,
                guest_house_id: 0,
                room_type_id: 0
            },
            id: 0,
            officeTypeList: [],
            officeList: [],
            guestHouseList: [],
            roomTypeList: [],
            item: '',
            myFilter: (option, text, search) => {
                const temp = search.toLowerCase()
                return option.text_en.toLowerCase().indexOf(temp) > -1 ||
                option.text_bn.toLowerCase().indexOf(temp) > -1
            }
        }
    },
    computed: {
        orgList: function () {
          return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
        },
        // roomTypeList: function () {
        //   return this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1)
        // },
        formTitle () {
            return (this.editItemId === 0) ? this.$t('elearning_config.duplicate_certificate_fee') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.room_rent') + ' ' + this.$t('elearning_config.update')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('elearning_config.fee_amount'), class: 'text-center' },
                { label: this.$t('elearning_config.effective_date'), class: 'text-center' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center w-15' }
            ]

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'fee_amount' },
                { key: 'effective_date' },
                { key: 'status' },
                { key: 'action' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'fee_amount' },
                { key: 'effective_date' },
                { key: 'status' },
                { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        }
    },
    async created () {
        if (this.$store.state.Auth.activeRoleId !== 1) {
            this.search.org_id = this.$store.state.Auth.authUser.org_id
        }
        this.loadData()
    },
    mounted () {
        core.index()
    },
    watch: {
    },
    methods: {
        changeStatus (baseUrl, uri, item, destination = null, dropdownName = null) {
            Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
            if (response.success) {
                /** The following line commented as list is reloaded */
                // Store.dispatch('toggleStatus', item)
                if (destination !== null && dropdownName !== null) {
                Store.dispatch('toggleDropdownItemStatus', { itemId: item.id, destination: destination, dropdownName: dropdownName })
                }
                window.vm.$toast.success({
                title: 'Success',
                message: 'Data Updated Successfully',
                color: '#D6E09B'
                })
            } else {
                window.vm.$toast.error({
                title: 'Error',
                message: 'Operation failed! Please, try again.'
                })
            }
            Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
            })
        },
        dataChange () {
            this.loadData()
        },
        details (item) {
            this.item = item
        },
        async searchData () {
            this.loadData()
        },
        remove (item) {
            this.changeStatus(trainingElearningServiceBaseUrl, duplicateCertificateFeeToggleStatus, item)
        },
        loadData () {
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(trainingElearningServiceBaseUrl, duplicateCertificateFeeList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getCustomDataList(data) {
            const listData = data.map(item => {
            const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
            const orgData = {}
            if(typeof orgObj !== 'undefined') {
              orgData.org = orgObj.text_en
              orgData.org_bn = orgObj.text_bn
            } else {
              orgData.org = ''
              orgData.org_bn = ''
            }

            const officeTypeObj = this.$store.state.commonObj.officeTypeList.find(doc => doc.value === parseInt(item.office_type_id))
            const officeTypeData = {}
            if(typeof officeTypeObj !== 'undefined') {
              officeTypeData.office = officeTypeObj.text_en
              officeTypeData.office_bn = officeTypeObj.text_bn
            } else {
              officeTypeData.office = ''
              officeTypeData.office_bn = ''
            }

            const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
            const officeData = {}
            if(typeof officeObj !== 'undefined') {
              officeData.office_name = officeObj.text_en
              officeData.office_name_bn = officeObj.text_bn
            } else {
              officeData.office_name = ''
              officeData.office_name_bn = ''
            }

            const guestHouseObj = this.$store.state.TrainingElearning.commonObj.guestHouseList.find(doc => doc.value === parseInt(item.guest_house_id))
            const guestHouseData = {}
            if(typeof guestHouseObj !== 'undefined') {
              guestHouseData.guest_house = guestHouseObj.text_en
              guestHouseData.guest_house_bn = guestHouseObj.text_bn
            } else {
              guestHouseData.guest_house = ''
              guestHouseData.guest_house_bn = ''
            }

            const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
            const fiscalYearData = {}
            if(typeof fiscalYearObj !== 'undefined') {
              fiscalYearData.fiscal_year = fiscalYearObj.text_en
              fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
            } else {
              fiscalYearData.fiscal_year = ''
              fiscalYearData.fiscal_year_bn = ''
            }

            const roomTypeObj = this.$store.state.TrainingElearning.commonObj.roomTypeList.find(doc => doc.value === parseInt(item.room_type_id))
            const roomTypeData = {}
            if(typeof roomTypeObj !== 'undefined') {
              roomTypeData.room_type = roomTypeObj.text_en
              roomTypeData.room_type_bn = roomTypeObj.text_bn
            } else {
              roomTypeData.room_type = ''
              roomTypeData.room_type_bn = ''
            }

            return Object.assign({}, item, orgData, officeData, fiscalYearData, roomTypeData, officeTypeData, guestHouseData)
          })
          return listData
        }
    }
}
</script>
